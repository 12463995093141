import React, { useState } from 'react';
import Img from 'gatsby-image';

import Button from '../../Button';
import Dots from '../../Dots';
import './index.css';

export default ({ className, logos }) => {
  const [viewMore, setViewMore] = useState(false);
  return (
    <section className="services-our-clients">
      <div className="top">
        <h2>Clients</h2>
        <Dots amount={3} />
      </div>
      <div
        className={`logos-container ${className} ${
          viewMore ? '' : 'view-more'
        }`}
      >
        {logos.map((logo, index) => (
          <div className={`logo-wrapper ${index > 8 ? 'extra' : ''}`}>
            <Img
              key={index}
              fluid={logo.src.childImageSharp.fluid}
              alt={logo.alt}
              imgStyle={{objectFit: "contain"}}
            />
          </div>
        ))}
      </div>
      <Button
        onClick={() => {
          setViewMore(!viewMore);
        }}
        text={viewMore ? 'view less' : 'view more'}
      />
    </section>
  );
};
