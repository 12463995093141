import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import AppContext from '../../contexts/AppContext';

// Sections
import Hero from '../../components/workSections/Hero';
import BlogSection from '../../components/workSections/BlogSection';

const pageTitle = 'Featured Software Development & Design | Devetry';
const pageDescription =
  'Check out the latest work from Devetry! View our software development, design, and strategic partnerships.';

const WorkPageTemplate = ({ clientLogos, caseStudies, heroImage }) => {
  const { setActivePage } = useContext(AppContext);

  useEffect(() => {
    setActivePage('work');
  }, [setActivePage]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
      </Helmet>
      <div className="root-container">
        <Hero
          title="Work We Stand Behind"
          description="Peruse some of our favorite projects."
          image={heroImage}
        />
        <BlogSection logos={clientLogos} caseStudies={caseStudies} />
      </div>
    </>
  );
};

WorkPageTemplate.propTypes = {};

const WorkPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const { edges } = data.allMarkdownRemark;
  const caseStudies = edges.map((item) => ({
    ...item.node.frontmatter,
    ...item.node.fields
  }));
  const heroImage = data.file;

  return (
    <WorkPageTemplate
      clientLogos={frontmatter.clientLogos}
      caseStudies={caseStudies}
      heroImage={heroImage}
    />
  );
};

WorkPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default WorkPage;

export const pageQuery = graphql`
  query WorkPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "Work/index" } }) {
      frontmatter {
        templateKey
        clientLogos {
          src {
            childImageSharp {
              fluid(maxWidth: 900, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "CaseStudy/index" } } }
      sort: { fields: [frontmatter___date], order: [DESC] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            cardImage {
              src {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
            cardTitle
            cardSubtitle
            tags
            heroLogo {
              src {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
          }
        }
      }
    }
    file(relativePath : {
      eq: "whiteboard-session2.jpg"
    }) {
      childImageSharp {
        fluid(quality: 85, maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
