import React, { useState, useEffect } from 'react';

import BigCard from '../../workCards/BigWorkCard';
import SmallCard from '../../workCards/SmallWorkCard';
import LogoSection from '../../servicesSections/OurClients';
import SelectionBar from '../../SelectionBar';
import './index.css';

const avalailableCategories = [
  'All',
  'Design',
  'Software',
  'Strategy',
];

export default ({ logos, caseStudies }) => {
  const secondaryCases = caseStudies ? caseStudies.slice(6) : [];
  const [selectionBarItems, setSelectionBarItems] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    const categoriesToShow = ['All'];
    avalailableCategories.forEach((category) => {
      const showCat = caseStudies.find((item) =>
        item.tags.find((cat) => cat === category)
      );
      if (showCat) categoriesToShow.push(category);
    });
    setSelectionBarItems(categoriesToShow);
  }, []);

  useEffect(() => {
    if (!currentCategory) setCurrentItems([]);
    else filterByCategory(selectionBarItems[currentCategory]);
  }, [currentCategory]);

  const filterByCategory = (category) => {
    setCurrentItems(
      caseStudies.filter((item) =>
        item.tags.find((cat) => cat === category)
      )
    );
  };

  return (
    <section className="work-section">
      <SelectionBar
        items={selectionBarItems}
        currentCategory={currentCategory}
        setCurrentCategory={setCurrentCategory}
      />
      {!currentCategory ? (
        <>
          <div className="secondary-padding">
            {caseStudies && caseStudies[0] && (
              <BigCard caseStudy={caseStudies[0]} />
            )}
            <div className="small-card-container">
              {caseStudies && caseStudies[1] && (
                <SmallCard caseStudy={caseStudies[1]} />
              )}
              {caseStudies && caseStudies[2] && (
                <SmallCard caseStudy={caseStudies[2]} />
              )}
            </div>
          </div>
          {!currentCategory && (
            <LogoSection className="work-logos" logos={logos} />
          )}
          <div className="secondary-padding">
            <div className="small-card-container">
              {caseStudies && caseStudies[3] && (
                <SmallCard caseStudy={caseStudies[3]} />
              )}
              {caseStudies && caseStudies[4] && (
                <SmallCard caseStudy={caseStudies[4]} />
              )}
            </div>
            {caseStudies && caseStudies[5] && (
              <BigCard caseStudy={caseStudies[5]} />
            )}
          </div>
          {secondaryCases.length && (
            <div className="secondary-padding secondary-cases-section">
              <div className="small-card-container secondary-cases-container">
                {secondaryCases.map((caseStudy, index) => (
                  <SmallCard
                    key={index}
                    caseStudy={caseStudy}
                  />
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <div
          className="secondary-padding secondary-cases-section"
          style={{ marginTop: 87 }}
        >
          <div className="small-card-container secondary-cases-container">
            {currentItems.map((caseStudy, index) => (
              <SmallCard key={index} caseStudy={caseStudy} />
            ))}
          </div>
        </div>
      )}
    </section>
  );
};
