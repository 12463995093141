import React from 'react';

import Img from 'gatsby-image';
import { Link } from 'gatsby';

import ArrowButton from '../ArrowButton';
import blueDot from '../../img/blue-dot.svg';
import './index.css';
import recentwork from '../../img/recent-work-2.png';

export default ({ caseStudy }) => {
  return (
    <div className="small-work-card">
      <Link to={caseStudy.slug}>
        <div className="image-container">
          {!caseStudy.cardImage ? (
            <img
              className="work-image"
              src={recentwork}
              alt="work"
            />
          ) : (
            <Img
              className="work-image"
              fluid={caseStudy.cardImage.src.childImageSharp.fluid}
              alt={caseStudy.cardImage.alt}
            />
          )}
          <ArrowButton />
          <img className="b-circle" src={blueDot} alt="blue-dot" />
          <Img
            className="logo"
            fluid={caseStudy.heroLogo.src.childImageSharp.fluid}
            alt={caseStudy.heroLogo.alt}
            imgStyle={{
              objectFit: "contain",
              objectPosition: "left"
            }}
          />
        </div>
        <p className="header">{caseStudy.cardTitle}</p>
        <p className="c-title">{caseStudy.cardSubtitle}</p>
        <p className="tags">{caseStudy.tags.join(', ')}</p>
      </Link>
    </div>
  );
};
